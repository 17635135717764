.contact-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-header {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}

.contact-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.title-accent {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary-color), var(--primary-color));
  border-radius: 2px;
}

.contact-subtitle {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.contact-content {
  text-align: center;
}

.contact-content h2 {
  font-size: 2.8rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
}

.contact-card {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid rgba(20, 190, 240, 0.1);
}

.email-icon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  animation: float 3s ease-in-out infinite;
}

.contact-text {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;
  margin-bottom: 2rem;
}

.email-link {
  display: inline-block;
  font-size: 1.4rem;
  color: var(--primary-color);
  font-weight: 600;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  background: linear-gradient(45deg, rgba(20, 190, 240, 0.1), rgba(40, 50, 140, 0.1));
  transition: all 0.3s ease;
}

.email-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(20, 190, 240, 0.2);
  background: linear-gradient(45deg, rgba(20, 190, 240, 0.2), rgba(40, 50, 140, 0.2));
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .contact-section {
    padding: 60px 0;
  }

  .contact-content h2 {
    font-size: 2.2rem;
  }

  .contact-card {
    padding: 2rem;
  }

  .contact-text {
    font-size: 1.1rem;
  }

  .email-link {
    font-size: 1.2rem;
  }
} 