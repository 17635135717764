:root {
  --primary-color: #28328c;
  --secondary-color: #14bef0;
  --text-color: #333333;
  --background-color: #f5f7fa;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Adjust this value based on your navbar height */
}

/* Add this if you want to disable smooth scroll for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* Modern Typography */
body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Modern Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Modern Button Styles */
.button {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  color: white;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(20, 190, 240, 0.2);
}

/* Modern Card Styles */
.card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

/* Modern Section Styles */
.section {
  padding: 100px 0;
  position: relative;
}

/* Gradient Text */
.gradient-text {
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Modern Grid */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Animations */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeUp {
  animation: fadeUp 0.6s ease forwards;
}

/* Modern Form Styles */
.input {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px 16px;
  color: var(--text-light);
  width: 100%;
  transition: all 0.3s ease;
}

.input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(20, 190, 240, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
  
  .container {
    padding: 0 1rem;
  }
} 